import React from "react"
import ImagePreview from "../_components/ImagePreview/ImagePreview"

const MapPreview = () => {
    return (
        <div className="map">
            <ImagePreview
                src={'https://download.xela.co/taste/taa/taste-london-2024-12.jpg'}
            />
        </div>
    )
}

export default MapPreview
