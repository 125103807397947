import * as React from "react"
import { saveAs } from 'file-saver'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import Layout from "../../_components/Layout/Layout"
import "./styles.scss"
import MainTitle from "../MainTitle/MainTitle"

export default function ImagePreview({ src }) {
    const downloadImage = () => {
        saveAs(src, 'taste-event-map')
    }

    return (
        <div className="image-preview">
            <Layout title="MapPreview">
                <MainTitle>Find your way <br /> around Taste</MainTitle>
                <div className="tools">
                    <button
                        className="download-button"
                        onClick={(e) => downloadImage(e)}
                    >
                        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_425_7932)">
                                <path d="M0 30.9397V35H34V30.9397H0ZM30.8758 16.9559L17.0447 28.5928L3.53099 16.9397L6.19143 13.8701L15.1816 21.6253V0H19.2496V21.4304L28.256 13.8538L30.8758 16.9559Z" fill="#231F20" />
                            </g>
                            <defs>
                                <clipPath id="clip0_425_7932">
                                    <rect width="34" height="35" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>

                <TransformWrapper>
                    {() => (
                        <TransformComponent>
                            <img src={src} alt="preview" />
                        </TransformComponent>
                    )}
                </TransformWrapper>
            </Layout>
        </div>
    )
}
